import React from 'react';
import { graphql } from 'gatsby';
import {
  CodeSnippet,
  List,
  PageHero,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../components';
import pageHeroData from '../../../data/pages/components.yml';

import SectionHierarchy from './partials/_SectionHierarchy';

const snippetSimple = require('raw-loader!../../../data/snippets/module-simple.example');
const snippetToggling = require('raw-loader!../../../data/snippets/module-toggling.example');
const snippetCardGroups = require('raw-loader!../../../data/snippets/module-cardgroups.example');
const snippetHeaderControls = require('raw-loader!../../../data/snippets/module-headercontrols.example');
const snippetMiniViz = require('raw-loader!../../../data/snippets/module-miniviz.example');
const snippetHiddenEmpty = require('raw-loader!../../../data/snippets/module-hiddenempty.example');

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="code"
      title="Modules Overview"
      designCode
      subnav="components">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Modules"
        tierThree="Overview"
      />

      <SectionHierarchy data={data} />

      <Section title="Simple Example">
        <Paragraph>
          In order to setup a card group, you first need a data card with a
          visualization to place inside of it. The example below is pretty
          basic. It displays a histogram visualization in the{' '}
          <strong>collapsed</strong> view mode, and will switch to{' '}
          <strong>preview</strong> view mode after clicking the header.
        </Paragraph>

        <CodeSnippet
          id="snippet-simple"
          disableCodeEditing
          platform="React"
          code={snippetSimple}
        />

        <SectionSubhead>Toggling</SectionSubhead>
        <Paragraph>
          To make it easier to manage the selected items in a visualization, use
          the custom hook <code>useToggleArray()</code>.
        </Paragraph>

        <Paragraph>
          This function accepts an array of strings that should match the labels
          assigned to the <code>items</code> prop and represent the initially
          selected values, if any. The function returns two values: an array of
          currently selected <code>labels</code> and a function to pass to the{' '}
          <code>onToggleItem</code> prop of the Visualization.
        </Paragraph>

        <CodeSnippet
          id="snippet-toggling"
          disableCodeEditing
          platform="React"
          code={snippetToggling}
        />

        <SectionSubhead>Card Groups</SectionSubhead>
        <Paragraph>
          In production, the data cards would likely be generated using a
          predetermined data structure and helper functions. Architectural
          decisions like that are up to the consuming project, but this example
          should at least show the functionality and how these components are
          tied together.
        </Paragraph>

        <Paragraph>
          Now that we have a data card ready, let's add it to a card group:
        </Paragraph>

        <CodeSnippet
          id="snippet-card-groups"
          disableCodeEditing
          platform="React"
          code={snippetCardGroups}
        />

        <Paragraph>
          And voilà! This is a very basic configuration, but you should now have
          a card group with the header “Example”, which contains a data card for
          “Quarters” and a histogram visualization that can toggle individual
          items.
        </Paragraph>

        <Paragraph>
          Notice that the card group handles the logic for expanding and
          collapsing so the consuming project can focus on the data cards and
          visualizations.
        </Paragraph>

        <Paragraph>
          Of course, in reality, you will need to actually do something with the
          <code>selectedLabels</code>, such as filter video clips, and you might
          need to pass down <code>isEmpty</code> or <code>isHidden</code> via
          props to the underlying <code>{'<DataCard />'}</code>. Check out the
          additional examples below to learn more.
        </Paragraph>
      </Section>

      <Section title="More Examples">
        <Paragraph>
          Now that you have a basic understanding of how the card groups, data
          cards, and visualizations relate to each other, we can take a look at
          some more complex examples that include things like
          mini-visualizations, header controls, and more.
        </Paragraph>
        <SectionSubhead>Header Controls</SectionSubhead>
        <Paragraph>
          The header of the data card contains two parts: the{' '}
          <strong>title</strong> and the
          <strong>controls</strong>. The title is the string of text displayed
          to describe the data card, and the controls are aligned on the
          opposite side of the card. Any ReactNode can be inserted in the
          controls, however they should never be taller than <code>1.5rem</code>
          .
        </Paragraph>
        <Paragraph>
          The most common elements that will be used in the header controls are
          mini-visualizations and buttons or action lists. You may need to show
          different elements based on the view mode, like the example below:
        </Paragraph>
        <CodeSnippet
          id="snippet-header-controls"
          disableCodeEditing
          platform="React"
          code={snippetHeaderControls}
        />
        <Paragraph>
          In this example, a mini-segmented bar visualization will display in
          <strong>collapsed</strong> view mode, and a button that says “Reports”
          and an action list with options to change the view mode will display
          in all other view modes.
        </Paragraph>
        <SectionSubhead>Tooltip</SectionSubhead>
        <Paragraph>
          The visualization components utilize the description values in the
          items array for two purposes:
        </Paragraph>

        <List className="uni-margin--one--bottom">
          <li>Show the description when hovering over an item in a tooltip.</li>
          <li>In ARIA props for accessibility.</li>
        </List>

        <Paragraph>
          In order for the tooltips to display, there must be a{' '}
          <code>{'<Tooltip />'}</code> initialized somewhere in the current
          page.{' '}
        </Paragraph>

        <Paragraph>
          If the tooltips need to display within a data card that is in the
          <strong>expanded</strong> or <strong>full</strong> view modes, then
          the <code>{'<Tooltip />'}</code> needs to be within the{' '}
          <code>{'<DataCard />'}</code> to avoid <code>z-index</code> issues.
        </Paragraph>

        <Paragraph>
          Some visualizations are able to displayed in a smaller form, within
          the header, in the <strong>collapsed</strong> view mode. In that case,
          there will be a mini version of the visualization available.
          Currently, the only exception is the toggle, which does not have a
          mini version since it is only meant to be used in the{' '}
          <strong>collapsed</strong> view mode.
        </Paragraph>

        <Paragraph>
          For example, to place a histogram in the data card header in the
          <strong>collapsed</strong> view mode, we can import the{' '}
          <code>HistogramMini</code> from the Uniform library, and place it in
          the <code>headerControls</code> prop. Below is the same{' '}
          <code>QuarterCard</code> component from earlier, with the addition of
          a <code>HistogramMini</code>.
        </Paragraph>

        <CodeSnippet
          id="snippet-mini-viz"
          disableCodeEditing
          platform="React"
          code={snippetMiniViz}
        />
        <SectionSubhead>Mini Visualizations</SectionSubhead>
        <Paragraph>
          Some visualizations are able to displayed in a smaller form, within
          the header, in the <strong>collapsed</strong> view mode. In that case,
          there will be a mini version of the visualization available.
          Currently, the only exception is the toggle, which does not have a
          mini version since it is only meant to be used in the{' '}
          <strong>collapsed</strong> view mode.
        </Paragraph>
        <Paragraph>
          For example, to place a histogram in the data card header in the
          <strong>collapsed</strong> view mode, we can import the{' '}
          <code>HistogramMini</code>
          from the Uniform library, and place it in the{' '}
          <code>headerControls</code> prop. Below is the same{' '}
          <code>QuarterCard</code> component from earlier, with the addition of
          a <code>HistogramMini</code>.
        </Paragraph>
        <CodeSnippet
          id="snippet-mini-viz"
          disableCodeEditing
          platform="React"
          code={snippetMiniViz}
        />
        <SectionSubhead>Hidden and Empty Data Cards</SectionSubhead>
        <Paragraph>
          Oftentimes, there will data cards which need to be hidden by default,
          either because they contain no data (empty) or because they aren't as
          important as others. In order for the parent card group to organize
          them accordingly, the <code>isHidden</code> and <code>isEmpty</code>{' '}
          props must be used in the data card:
        </Paragraph>
        <CodeSnippet
          id="snippet-hidden-empty"
          disableCodeEditing
          platform="React"
          code={snippetHiddenEmpty}
        />
        <Paragraph>In the example above:</Paragraph>
        <List>
          <li>
            Data Cards 1, 2, and 4 would display by default because they are
            neither empty nor hidden.
          </li>
          <li>
            Data Card 5 would display after pressing the “More Examples” button
            at the bottom of the card group.
          </li>
          <li>
            Data Cards 3 and 6 would display after pressing the “2 cards with no
            data” button that appears after pressing the “More Examples” button.
          </li>
        </List>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query modulesCodeOverviewQuery($tierOne: String = "Components") {
    ...indexList
  }
`;
