import React from 'react';
import { ItemsListIndexPage, Paragraph, Section } from '../../../../components';

const SectionHierarchy = ({ data, linksToShow }) => {
  return (
    <Section title="Hierarchy">
      <img
        src={require('../../../../imgs/diagrams/module-system-diagram.png')}
        // eslint-disable-next-line max-len
        alt="Diagram showing multiple modules within a product experience. The first module shows a visualization nested within a data card within a card group, and the others are examples of potential other modules."
        className="uni-margin--one--bottom"
      />
      <Paragraph>
        The module system is the foundation of Hudl's newest experiences. It is
        what constrains the context and orients our user in the interface. The
        system consists of several layers. It relies on strong parent-child
        relationships to maintain our user's expected experience. These layers
        support one-to-many relationships. Put simply, parent data can reference
        and manipulate many child records in another data set. For example, if a
        football user wants to filter between ODK values, the data within
        Situations, Formations and other data sets will change based on their
        selection.
      </Paragraph>

      <Paragraph>
        The <strong>product experience</strong> is the viewport within the
        browser window or app screen.
      </Paragraph>

      <Paragraph>
        A <strong>module</strong> makes up the outermost layer of the layout
        system. They are the parent of other components specific to the module
        system. Current Uniform components support tight relationships between
        data and video, but in the future they will support things like clip
        lists, conversations, navigation and more. They also handle arranging
        the components within them responsively.
      </Paragraph>

      <Paragraph>
        A <strong>card group</strong> acts as the container for a subset of
        data. They are the parent of data-specific components within the module
        system. They enable many, related data sets to display and manipulate
        them at once.
      </Paragraph>

      <Paragraph>
        A <strong>data card</strong> displays the data for a single subject and
        is the parent to many types of data visualizations. They allow for
        static or dynamic content within the same category.
      </Paragraph>

      <Paragraph>
        <strong>Visualizations</strong> are the specific module system
        components used to represent data graphically. They communicate data in
        many ways. They allow filtering of data within the product experience
        based on the user's preferred context.
      </Paragraph>

      <ItemsListIndexPage
        data={data}
        tierTwo="modules"
        listType="visual"
        listLayout="3"
        namesToShow={linksToShow}
      />
    </Section>
  );
};

export default SectionHierarchy;
